$rooms: 5;
$frames: $rooms + 1;

.gallery-room {
  background: white;
  transform: scale(1);
  transition: 0.5s;
  @media (max-aspect-ratio: 1/1) {
    font-size: calc(16 * 100vh / 1200);
  }

  /* landscape orientation */
  @media (min-aspect-ratio: 1/1) {
    font-size: calc(16 * 100vw / 1200);
  }

  &.zoom-in {
    transform: scale(0.2) !important;
    transition: 1s;
  }
  --exposition-width-1: calc(var(--body-actual-width) - var(--frame-size) * 2);
  --exposition-height-1: calc(var(--body-actual-height) - var(--frame-size) * 2);
}

@media (orientation: landscape) {
  .gallery-room {
    --frame-size: calc(var(--body-actual-width) / 100 * 2.5);
    --exposition-width: calc(var(--body-actual-width) - var(--frame-size) * 2);
    --exposition-height: calc(var(--body-actual-height) - var(--frame-size) * 2);
    --gallery-width: calc(var(--exposition-width) * #{$rooms} + var(--frame-size) * #{$frames});
    --gallery-height: calc(var(--exposition-height) * #{$rooms} + var(--frame-size) * #{$frames});
    width: var(--gallery-width);
    height: var(--gallery-height);
  }
}

@media only screen and (orientation: portrait) {
  .gallery-room {
    --frame-size: calc(var(--body-actual-height) / 100 * 2.5);
    --exposition-width: calc(var(--body-actual-height) - var(--frame-size) * 2);
    --exposition-height: calc(var(--body-actual-width) - var(--frame-size) * 2);
    --gallery-width: calc(var(--exposition-width) * #{$rooms} + var(--frame-size) * #{$frames});
    --gallery-height: calc(var(--exposition-height) * #{$rooms} + var(--frame-size) * #{$frames});
    width: var(--gallery-height);
    height: var(--gallery-width);
    overflow: hidden;
  }
  .gallery {
    transform: rotate(-90deg);
    transform-origin: left top;
    position: absolute;
    top: var(--gallery-width);
    left: 0;
    width: var(--gallery-width);
    height: var(--gallery-height);
  }
}

.gallery {
  display: grid;
  grid-template-columns:
          [border-1] var(--frame-size)
          [exposition-1] var(--exposition-width)
          [border-2] var(--frame-size)
          [exposition-2] var(--exposition-width)
          [border-3] var(--frame-size)
          [exposition-3] var(--exposition-width)
          [border-4] var(--frame-size)
          [exposition-4] var(--exposition-width)
          [border-5] var(--frame-size)
          [exposition-5] var(--exposition-width)
          [border-6] var(--frame-size);
  grid-template-rows:
          [border-1] var(--frame-size)
          [exposition-1] var(--exposition-height)
          [border-2] var(--frame-size)
          [exposition-2] var(--exposition-height)
          [border-3] var(--frame-size)
          [exposition-3] var(--exposition-height)
          [border-4] var(--frame-size)
          [exposition-4] var(--exposition-height)
          [border-5] var(--frame-size)
          [exposition-5] var(--exposition-height)
          [border-6] var(--frame-size);
}

@for $col from 1 to $rooms + 1 {
  @for $row from 1 to $rooms + 1 {
    .exposition-#{$row}-#{$col} {
      grid-column: exposition-#{$col};
      grid-row: exposition-#{$row};
    }
  }
}

.home-frame-top {
  grid-row: border-3;
  grid-column: border-3 / span 3;
}

.home-frame-bottom {
  grid-row: border-4;
  grid-column: border-3 / span 3;
}

.home-frame-left {
  grid-row: border-3 / span 3;
  grid-column: border-3;
}

.home-frame-right {
  grid-row: border-3 / span 3;
  grid-column: border-4;
}

.border-vertical-1 {
  grid-column: border-1;
  grid-row-start: border-1;
  grid-row-end: border-6;
}

.border-horizontal-1 {
  grid-row: border-1;
  grid-column-start: border-1;
  grid-column-end: border-6;
}

.border-vertical-2 {
  grid-column: border-2;
  grid-row-start: border-1;
  grid-row-end: border-6;
}

.border-horizontal-2 {
  grid-row: border-2;
  grid-column-start: border-1;
  grid-column-end: border-6;
}
