.home-frame {
    animation: 4s glitch-image infinite;
    overflow: hidden;
    .home-frame-border{
        width: 100%;
        height: 100%;
        > div {
            height: 33%;
            line-height: 50%;
            width: 33%;
        }
    }
}

.home-frame-border-text {
    font-size: 50%;
    color: white;
    font-family: 'Horizon Outline', serif;
    text-wrap: normal;
    white-space: normal;
    text-rendering: optimizeSpeed;
}

.home-frame-border-text-1 {
    color: black;
    z-index: 1000;
    background-color: white;
}

.home-frame-border-text-2 {
    color: white;
    background-color: black;
}

.home-frame-border-text-3 {
    color: black;
    z-index: 1000;
    background-color: white;
}

.home-frame-border-top {
    z-index: 2;
}

.home-frame-border-right {
    writing-mode: vertical-rl;
    z-index: 1;
}

.home-frame-border-left {
    writing-mode: vertical-lr;
    transform: rotate(180deg) scaleX(-1) scaleY(-1);
    z-index: 1;
    > div {
        transform: scaleX(-1);
    }
}

.home-frame-border-bottom {
    writing-mode: rl;
    z-index: 2;
    transform: rotate(180deg);
}
